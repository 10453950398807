import axios from "axios"
import DOMPurify from "dompurify"
import React, { useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { errors as ErrorMessages } from "../../constants/errorMessages"
import { BrowserDetails } from "../utility/browserDetails"
import {
  checkInvalid,
  checkValidEmail,
  checkValidName,
} from "./../utility/validation"
import { setHubspotutkCookie } from "./FormBuilder/Form-Function"

let SignupForm = props => {
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [disableButton, setDisableButton] = useState()

  const [humioLogAvailable, setHumioLogAvailable] = useState(false)

  const [defaultCountryCode, setDefaultCountryCode] = useState("us")
  const [dailCodeLength, setDailCodeLength] = useState(0)

  const [name, setName] = useState("")
  const [namef, setNamef] = useState("")
  const [namel, setNamel] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNum, setPhone] = useState("")
  const [selectedOptions, setSelectedOptions] = useState("")
  const [company, setCompany] = useState("")
  const [apiphoneNumberCountryCode, setapiphoneNumberCountryCode] = useState("")

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [currentToolError, setCurrentToolError] = useState(false)

  const [isInvalidEmail, setIsInvalidEmail] = useState(false)

  const [IpStack, setIpStack] = useState("")

  const [countryPhoneCode, setCountryPhoneCode] = useState(null)

  const [nameIsOffFocus, setNameIsOffFocus] = useState(false)
  const [emailIsOffFocus, setEmailIsOffFocus] = useState(false)
  const [compIsOffFocus, setCompIsOffFocus] = useState(false)
  const [phoneIsOffFocus, setPhoneIsOffFocus] = useState(false)

  function checkCurrencySupport(currencyCode) {
    const supported_currencies = [
      "AED",
      "AUD",
      "BHD",
      "CAD",
      "EUR",
      "NZD",
      "QAR",
      "SAR",
      "SGD",
      "USD",
      "ZAR",
      "GBP",
    ]

    return supported_currencies.includes(currencyCode) ? currencyCode : "USD"
  }

  function BrowserType() {
    var Browser,
      UsrAg = navigator.userAgent

    if (UsrAg.indexOf("Firefox") > -1) {
      Browser = "Mozilla Firefox"
    } else if (UsrAg.indexOf("SamsungBrowser") > -1) {
      Browser = "Samsung Internet"
    } else if (UsrAg.indexOf("Opera") > -1 || UsrAg.indexOf("OPR") > -1) {
      Browser = "Opera"
    } else if (UsrAg.indexOf("Trident") > -1) {
      Browser = "Microsoft Internet Explorer"
    } else if (UsrAg.indexOf("Edge") > -1) {
      Browser = "Microsoft Edge (Legacy)"
    } else if (UsrAg.indexOf("Edg") > -1) {
      Browser = "Microsoft Edge (Chromium)"
    } else if (UsrAg.indexOf("Chrome") > -1) {
      Browser = "Google Chrome or Chromium"
    } else if (UsrAg.indexOf("Safari") > -1) {
      Browser = "Apple Safari"
    } else {
      Browser = "unknown"
    }

    return "You are using: " + Browser
  }

  const checkHumioLogger = () => {
    if (window.logger) {
      console.log("Humio logger available")
      !humioLogAvailable && setHumioLogAvailable(true)
    } else {
      console.log("Humio logger not-available")
      humioLogAvailable && setHumioLogAvailable(false)
    }
  }

  let choices = [
    { label: "Datto", value: "Datto" },
    { label: "NinjaRMM", value: "NinjaRMM" },
    { label: "Kaseya", value: "Kaseya" },
    { label: "Atera", value: "Atera" },
  ]

  function CheckIpdata(IpResponse) {
    let ipData = IpResponse
    Object.keys(IpStack).map(key => {
      ipData = {
        ...ipData,
        [key]:
          IpResponse[key] != null && IpResponse[key] != ""
            ? IpResponse[key]
            : IpStack[key],
      }
    })
    return ipData
  }

  useEffect(() => {
    let checkIsReferral = sessionStorage.getItem("sopsreff")
    checkHumioLogger()
    axios.get(process.env.IP_STACK_API).then(function(response) {
      setIpStack(CheckIpdata(response?.data))
      const cPhoneCode = response?.data?.location?.calling_code
      setCountryPhoneCode(
        cPhoneCode?.includes(",") ? cPhoneCode?.split(",")[0] : cPhoneCode
      )
      setDefaultCountryCode(response?.data?.country_code?.toLowerCase())
    })

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function() {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.CAPTCHA_KEY}`,
      function() {
        // console.log("Script loaded!")
      }
    )
  }, [])

  useEffect(() => {
    let timer = setTimeout(splitFirstAndLastName, 1000)

    return () => clearTimeout(timer)
  }, [name])

  useEffect(() => {
    if (email.length > 0 && checkValidEmail(email)) {
      setEmailError(false)
    }
  }, [email])

  useEffect(() => {
    if (company.length > 0 && !checkInvalid(company)) {
      setCompanyError(false)
    }
  }, [company])

  useEffect(() => {
    if (name.length > 0 && checkValidName(name)) {
      setNameError(false)
    }
  }, [name])

  useEffect(() => {
    if (isInvalidEmail) {
      setEmailError(true)
    }
  }, [isInvalidEmail])

  const checkEmail = () => {
    if (email === "" || !checkValidEmail(email) || isInvalidEmail) {
      setEmailError(true)
      setDisableButton(false)
    } else {
      setEmailError(false)
    }
  }

  const checkCompany = () => {
    if (company === "" || checkInvalid(company)) {
      setCompanyError(true)
      setDisableButton(false)
    } else {
      setCompanyError(false)
    }
  }

  const checkName = () => {
    if (name === "" || !checkValidName(name)) {
      setNameError(true)
      setDisableButton(false)
    } else {
      setNameError(false)
    }
  }

  const isSubmitValid = () => {
    return (
      email !== "" &&
      checkValidEmail(email) &&
      company !== "" &&
      !checkInvalid(company) &&
      name !== "" &&
      checkValidName(name)
    )
  }

  //makes a post request and calls a function at the end
  function postRequest(url, data, callback, withCredentials) {
    var xhr = new XMLHttpRequest()

    xhr.open("POST", url)

    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.withCredentials = withCredentials ? true : false

    xhr.send(data)

    xhr.onreadystatechange = () => callback(xhr)
  }

  //Gets Name from user and splits it int o first_name and last_name
  function splitFirstAndLastName() {
    let fullname = name.trim()
    if (fullname.includes(" ")) {
      //split Name if includes a empty space
      setNamef(fullname.substr(0, fullname.indexOf(" ")))
      setNamel(fullname.substr(fullname.indexOf(" ") + 1))
    } else {
      //else set Name as first_name
      setNamef(fullname)
      setNamel("")
    }
  }

  const sendStatusHumioLog = (data, responseText, status) => {
    let log_data_success = {
      finalData: data,
      response: responseText,
    }

    //checks if humio logger is available before pushing
    checkHumioLogger()

    humioLogAvailable &&
      (ErrorMessages[status]
        ? window.logger.info(ErrorMessages[status].loggerInfo, {
            log_data_success,
          })
        : window.logger.info(ErrorMessages.UNKNOWN.loggerInfo, {
            log_data_success,
            responseStatus: status,
          }))
    humioLogAvailable && window.logger.flush()
  }

  //pushes event logs to humio log using beaver-logger
  const sendEventHumioLog = (data, responseText, message) => {
    let log_data = {
      finalData: data,
      response: responseText,
    }

    //checks if humio logger is available before pushing
    checkHumioLogger()

    humioLogAvailable &&
      window.logger.info(message, {
        log_data,
      })
    humioLogAvailable && window.logger.flush()
  }

  const handleSubmit = evt => {
    // evt.preventDefault()
    checkHumioLogger()

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.CAPTCHA_KEY, { action: "submit" })
        .then(token => {
          checkEmail()
          checkCompany()
          checkName()

          if (isSubmitValid()) {
            //changes signup button to loading state
            setIsAPILoading(true)

            props.ErrorTextSetter("")
            props.DisplayErrorSetter(false)

            //data object for Hubspot form
            var data = {
              fields: [
                {
                  name: "firstname",
                  value: handleEmptyName(namef),
                },
                {
                  name: "lastname",
                  value: handleEmptyName(namel),
                },
                {
                  name: "email",
                  value: DOMPurify.sanitize(email),
                },
                {
                  name: "company",
                  value: DOMPurify.sanitize(company),
                },
                {
                  name: "mobilephone",
                  value: DOMPurify.sanitize(phoneNum.slice(dailCodeLength)),
                },
                {
                  name: "signup_info",
                  value: `${
                    window.logger ? "Humio available" : "Humio not Available"
                  },  hubspot-cookie : ${
                    setHubspotutkCookie("hubspotutk")
                      ? "available"
                      : "not available"
                  }, Browser: ${BrowserDetails.browserName}, Browser version: ${
                    BrowserDetails.fullVersion
                  }, OS : ${BrowserDetails.OSName}, Device type: ${
                    BrowserDetails.deviceType
                  }, Device resolution: ${BrowserDetails.resolution}`,
                },
                {
                  name: "continent",
                  value: IpStack?.continent_name || "No Continent",
                },
                {
                  name: "continent_code",
                  value: IpStack?.continent_code || "No Continentcode",
                },
                {
                  name: "country_code",
                  value: IpStack?.country_code || "No Countrycode",
                },
                {
                  name: "country",
                  value: IpStack?.country_name || "No Country",
                },
                {
                  name: "state",
                  value: IpStack?.region_name || "No State",
                },
                {
                  name: "city",
                  value: IpStack?.city || "No City",
                },
                {
                  name: "zip",
                  value: IpStack?.zip || "No Zip",
                },
                {
                  name: "country_phone_code",
                  value: countryPhoneCode,
                },
                {
                  name: "sopsreferral",
                  value: sessionStorage.getItem("sopsreff") ?? "No Referral",
                },
                {
                  name: "sops_first_page_seen",
                  value:
                    sessionStorage.getItem("landing") ?? "No First page seen",
                },
                {
                  name: "sops_last_page_seen",
                  value: document.location.href,
                },
                {
                  name: "current_tool_set",
                  value: selectedOptions,
                },
              ],
              context: {
                hutk: setHubspotutkCookie("hubspotutk"),
                pageUri: document.URL,
                pageName: document.title,
              },
            }
            var final_data = JSON.stringify(data)

            //data object for Signup API
            var datap = {
              emailId: DOMPurify.sanitize(email),

              firstName: handleEmptyName(namef),

              lastName: handleEmptyName(namel),

              phoneNumber: DOMPurify.sanitize(phoneNum.slice(dailCodeLength)),

              companyName: DOMPurify.sanitize(company),

              captchaToken: token,

              mspTimeZone: IpStack?.time_zone?.id,

              phoneNumberCountryCode: apiphoneNumberCountryCode,

              countryCode: IpStack?.country_code,

              currencyCode: checkCurrencySupport(IpStack?.currency?.code),

              // //hubspotObject send with signupObject

              hubspotFields: {
                city: IpStack?.city,

                country: IpStack?.country_name,

                state: IpStack?.region_name,

                continent: IpStack?.continent_name,

                continentCode: IpStack?.continent_code,

                zip: IpStack?.zip,

                sopsReferral: sessionStorage.getItem("sopsreff"),

                sopsFirstPageSeen: sessionStorage.getItem("landing"),

                sopsLastPageSeen: document.location.href,

                currentToolSet: selectedOptions,

                signupInfo: `${
                  window.logger ? "Humio available" : "Humio not Available"
                },  hubspot-cookie : ${
                  setHubspotutkCookie("hubspotutk")
                    ? "available"
                    : "not available"
                }, Browser: ${BrowserDetails.browserName}, Browser version: ${
                  BrowserDetails.fullVersion
                }, OS : ${BrowserDetails.OSName}, Device type: ${
                  BrowserDetails.deviceType
                }, Device resolution: ${BrowserDetails.resolution}`,

                hutk: setHubspotutkCookie("hubspotutk"),

                pageUri: document.URL,

                pageName: document.title,
              },
            }

            var final_datap = JSON.stringify(datap)
            // console.log("Product Api call", final_datap)

            sendEventHumioLog(final_data, {}, "Before Hubspot Lead push ")

            //post request for Hubspot
            process.env.SEND_HUBSPOT_DATA_CLONE === "true" &&
              postRequest(
                process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
                final_data,
                function(xhr) {
                  if (xhr.readyState === 4) {
                    handleXHRResult(xhr, token, data)
                  }
                }
              )

            //logs signup send event
            sendEventHumioLog(final_datap, {}, "Before hitting signup API")

            //post request for Signup
            postRequest(
              process.env.PRODUCT_SIGNUP_API,
              final_datap,
              function(xhrp) {
                handleXHRPResult(xhrp, final_datap, data)
              },
              true
            )

            //logs signup send event
            sendEventHumioLog(final_datap, {}, "Signup send event")

            //logs hubspot send event
            // sendEventHumioLog(final_data, "Hubspot Lead ")
          }
        })
    })
  }

  const handleEmptyName = value => {
    return value === "" ? "" : DOMPurify.sanitize(value)
  }

  //handles response from Hubspot
  const handleXHRResult = (xhr, token, data) => {
    if (
      xhr.status === 200 ||
      JSON.parse(xhr.response).errors[0].errorType !== "INVALID_EMAIL"
    ) {
      xhr.status === 200
        ? sendEventHumioLog(
            data,
            xhr.responseText,
            "Hubspot Lead after response"
          )
        : sendEventHumioLog(
            data,
            xhr.responseText,
            "Hubspot Response Failure but valid Email"
          )

      setIsInvalidEmail(false)
    } else {
      // setIsInvalidEmail(true)
      setDisableButton(false)

      //changes signup button back to default state
      setIsAPILoading(false)

      sendEventHumioLog(
        data,
        xhr.responseText,
        "Hubspot Response Failure, with Invalid Email Error"
      )
    }
  }

  //handles reponse from Signup API
  const handleXHRPResult = (xhrp, final_datap, data) => {
    if (xhrp.readyState === 4) {
      var objp = JSON.parse(xhrp.responseText)
      // objp["status"] !== "IN_SESSION" && sendStatusHumioLog(final_datap, xhrp.responseText, objp["status"])
      sendStatusHumioLog(final_datap, xhrp.responseText, objp["status"])
      // sendStatusHumioLog(final_datap, xhrp.responseText, objp)

      data.fields.push({
        name: "signup_info",
        value: `Humio: ${
          window.logger ? "Humio available" : "Humio not Available"
        } , status : ${objp["status"]}, response : ${
          xhrp.responseText
        }, hubspot-cookie : ${
          setHubspotutkCookie("hubspotutk") ? "available" : "not available"
        }`,
      })
      var final_response_data = JSON.stringify(data)

      // postRequest(
      //   process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
      //   final_response_data,
      //   function() {
      //     return
      //   }
      // )

      //if below checks are valid redirects to received subDomain
      if (objp["status"] === "SUCCESS" || objp["status"] === "IN_SESSION") {
        !isAPILoading && setIsAPILoading(true)
        props.DisplayErrorSetter(false)
        window.location.href = `/signup/thank-you?src=${objp["subDomainName"]}`
      } else {
        //changes signup button back to default state
        setIsAPILoading(false)

        setDisableButton(false)
        props.DisplayErrorSetter(true)
        props.ErrorTextSetter(
          ErrorMessages[objp["status"]]
            ? ErrorMessages[objp["status"]].errorText
            : ErrorMessages.UNKNOWN.errorText
        )
      }
    }
  }

  const handleOnChange = (value, data, event, formattedValue) => {
    setPhone(value)
    setDailCodeLength(data.dialCode.length)
    setapiphoneNumberCountryCode(data.dialCode.toString())
  }

  return (
    <>
      <div className=" forms">
        <form>
          <div className="position-relative">
            <input
              id="name"
              type="text"
              name="NAME"
              style={{
                border: nameError ? "1px solid #ff0055" : "1px solid #190429",
              }}
              className={`fs name ${name.length > 0 ? "valid" : ""} ${
                name.length > 40 && nameIsOffFocus ? "input-elipse" : ""
              }`}
              value={name}
              onBlur={() => setNameIsOffFocus(true)}
              onFocus={() => setNameIsOffFocus(false)}
              onChange={e => setName(e.target.value)}
            />
            <span className="floating-label p14 position-absolute">
              {" "}
              <p>Name</p>
            </span>
            {name.length > 0 && nameIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>nice to meet you</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: nameError ? "block" : "none" }}
            >
              Please enter a valid name.
            </div>
          </div>

          <div className="position-relative">
            <input
              id="email"
              type="text"
              name="EMAIL"
              value={email}
              className={`email ${email.length > 0 ? "valid" : ""} ${
                email.length > 40 && emailIsOffFocus ? "input-elipse" : ""
              }`}
              style={{
                border: emailError ? "1px solid #ff0055" : "1px solid #190429",
              }}
              onBlur={() => setEmailIsOffFocus(true)}
              onFocus={() => setEmailIsOffFocus(false)}
              onChange={e => setEmail(e.target.value)}
            />
            <span className="floating-label p14 position-absolute">
              {" "}
              <p>Email address</p>
            </span>
            {email.length > 0 && emailIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>we wont spam you</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: emailError ? "block" : "none" }}
            >
              Please enter a valid email.
            </div>
          </div>

          <div className="position-relative">
            <input
              id="company"
              type="text"
              name="COMPANY"
              value={company}
              className={`company ${company.length > 0 ? "valid" : ""} ${
                company.length > 35 && compIsOffFocus ? "input-elipse" : ""
              }`}
              style={{
                border: companyError
                  ? "1px solid #ff0055"
                  : "1px solid #190429",
              }}
              onBlur={() => setCompIsOffFocus(true)}
              onFocus={() => setCompIsOffFocus(false)}
              onChange={e => setCompany(e.target.value)}
            />
            <span className="floating-label p14 position-absolute">
              {" "}
              <p>Company name</p>
            </span>
            {company.length > 0 && compIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>to create a domain for you</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: companyError ? "block" : "none" }}
            >
              Please enter a valid company name.
            </div>
          </div>

          <div className="position-relative phone-number">
            <PhoneInput
              id="phone"
              name="PHONENUMBER"
              country={defaultCountryCode}
              value={phoneNum}
              placeholder="Phone number"
              onBlur={() => setPhoneIsOffFocus(true)}
              onChange={handleOnChange}
            />
            {phoneNum.length > 11 && phoneIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>we respect your privacy</p>
              </span>
            )}
          </div>

          {/* <div className={`position-relative`}>
            <Select
              id="select"
              choices={choices}
              name="CURRENTTOOLSET"
              label={"Choose what you currently use"}
              onChange={val => {
                let options = val.map(v => v.value)
                setSelectedOptions(options.join(", "))
              }}
            />
            <div
              className="error-message error-bg er-ct"
              style={{
                display: currentToolError ? "block" : "none",
              }}
            >
              {" "}
              <p>Please select a value</p>{" "}
            </div>
          </div> */}

          <div
            className={`ct-btn ${isAPILoading ? "active-loading-ct-btn" : ""}`}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                //disables button on single click
                setDisableButton(true)
                handleSubmit()
              }}
              disabled={disableButton}
            >
              {isAPILoading ? (
                <div className="loading-anim">
                  LOADING
                  <span className="ellipsis-anim">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </div>
              ) : (
                "GET STARTED FOR FREE"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default SignupForm
