import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import ErrorIcon from "../assets/images/signup-error.svg"
import Pencil from "../assets/images/signup/pencil-emoji.svg"
import Frame from "../components/common/frame"
import SignupForm from "../components/common/sign-up-form-clone"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import "../styles/pages/signup.scss"

const SignUpClone = () => {
  const [displayError, setDisplayError] = useState(false)
  const [errorText, setErrorText] = useState("")

  return (
    <StaticQuery
      query={graphql`
        query contentBetaSignupV2Clone {
          SuperOps {
            pages(where: { title: "Public Beta" }) {
              title
              pageBlock {
                id
                content {
                  html
                }
              }
              navigationBlock {
                name
                slug
                isDropdown
              }
              seo {
                title
                description
                keywords
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="signup">
          {data.SuperOps.pages.map(page => {
            const { title, pageBlock, seo } = page

            let MainTitle =
              pageBlock[3].content.html
                .split("<code>")[0]
                .replace(/(<([^>]+)>)/gi, "") + " "
            let underlinedTitle = pageBlock[3].content.html
              .split("<code>")[1]
              .split("</code>")[0]
            return (
              <>
                <Frame seo={seo} prodtest>
                  <header>
                    <Navigation page={title} color={"#fff2f1"} />
                  </header>

                  <section className="betav2-main">
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <div className="left">
                            <div
                              data-sal="slide-up"
                              data-sal-delay="200"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <h1>
                                {MainTitle}

                                <div
                                  className={`position-relative underlined-text`}
                                >
                                  <span className="position-relative">
                                    {underlinedTitle}
                                  </span>
                                  <div>
                                    <svg
                                      className="position-absolute"
                                      width="451"
                                      height="18"
                                      viewBox="0 0 451 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.00001 10.3485C27.2221 8.74451 91.3099 5.89018 182.242 5.55475C255.494 5.28453 346.165 6.64889 446.927 11.6182"
                                        stroke="#FF0055"
                                        stroke-width="7.28314"
                                        stroke-linecap="round"
                                        class="svg-elem-1"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </h1>
                            </div>
                            <div
                              data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <span className="p18">
                                {parse(pageBlock[4].content.html)}
                              </span>
                            </div>
                            <div
                              data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <span className="p14">
                                {parse(pageBlock[5].content.html)}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div
                            data-sal="slide-up"
                            data-sal-delay="400"
                            data-sal-easing="ease-out-bounce"
                            data-sal-duration="700"
                          >
                            <div className="right">
                              <section className="cn-cta bt-cta bts-cta">
                                <Container>
                                  {/* <span
                                    className="info"
                                    style={{
                                      display: !displayError ? "block" : "none",
                                    }}
                                  >
                                    <center>
                                      <span className="p16">
                                        {parse(
                                          pageBlock[2].content.html
                                        )}
                                      </span>
                                    </center>
                                  </span> */}
                                  <div
                                    className="sign-api-error"
                                    style={{
                                      display: displayError ? "block" : "none",
                                    }}
                                  >
                                    <img
                                      src={ErrorIcon}
                                      height="10"
                                      width="10"
                                      className="signup-error-icon"
                                      alt="img"
                                    />
                                    <span className="p14">
                                      <p>{errorText}</p>
                                    </span>
                                  </div>
                                  <SignupForm
                                    DisplayErrorSetter={val =>
                                      setDisplayError(val)
                                    }
                                    ErrorTextSetter={val => setErrorText(val)}
                                  />
                                  <span className="action">
                                    <center>
                                      <p>
                                        By clicking "GET STARTED FOR FREE", you
                                        agree to SuperOps's{" "}
                                        <Link to="/terms">Terms of use</Link>{" "}
                                        and{" "}
                                        <Link to="/privacy">
                                          Privacy policy
                                        </Link>
                                        .
                                      </p>
                                    </center>
                                  </span>
                                  <span className="info-bottom">
                                    <img src={Pencil} alt="note" />
                                    <span className="p14">
                                      {parse(pageBlock[6].content.html)}
                                    </span>
                                  </span>
                                </Container>
                              </section>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <CTAThankYou />
                </Frame>
              </>
            )
          })}
        </div>
      )}
    />
  )
}

export default SignUpClone
